import { IWixAPI } from '@wix/yoshi-flow-editor';
import { WidgetData } from './getWidgetData';

const KEY = 'vodWidgetData';
const getKey = (compId: string) => `${KEY}-${compId}`;
const isSSR = (wixCodeApi: IWixAPI): boolean =>
  wixCodeApi.window.rendering.env === 'backend';

export const getWidgetWarmupData = (
  wixCodeApi: IWixAPI,
  compId: string,
): WidgetData | null => {
  return isSSR(wixCodeApi)
    ? null
    : wixCodeApi.window.warmupData.get(getKey(compId));
};

export const setWidgetWarmupData = (
  wixCodeApi: IWixAPI,
  compId: string,
  widgetData: WidgetData,
) => {
  if (isSSR(wixCodeApi)) {
    wixCodeApi.window.warmupData.set(getKey(compId), widgetData);
  }
};
