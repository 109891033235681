import { BaseModule } from './BaseModule';
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
import { dashify } from '../../utils/guid';
import settingsParams from '../../../../settingsParams';

type Props = {
  hasAccessToChannelByPricingPlan: boolean;
};

export class PricingPlansModule extends BaseModule<Props> {
  private isPricingPlanInstalled(): boolean {
    const {
      controllerConfig: { wixCodeApi },
      flowAPI: { environment },
    } = this.params;
    // we should also check for editor, because the app token for pricing plan is returned even if it's not installed
    if (environment.isEditor) {
      return false;
    }
    try {
      const appToken = wixCodeApi.site.getAppToken?.(
        '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
      );
      return Boolean(appToken);
    } catch (error) {
      return false;
    }
  }

  private getProps = async () => {
    const {
      flowAPI: { httpClient, settings },
      controllerConfig: { wixCodeApi },
    } = this.params;
    const {
      user: { currentUser },
    } = wixCodeApi;

    const pricingPlanInstalled = this.isPricingPlanInstalled();

    if (currentUser.loggedIn && pricingPlanInstalled) {
      const pricingPlanService = new PricingPlansAPI(httpClient);
      const channelId = dashify(
        settings.get(settingsParams.channelId),
      ) as string;
      const hasAccessToChannelByPricingPlan =
        await pricingPlanService.hasAccessToChannel(channelId, currentUser.id);

      return {
        hasAccessToChannelByPricingPlan,
      };
    }

    return {
      hasAccessToChannelByPricingPlan: false,
    };
  };

  private handleLogin = async () => {
    const props = await this.getProps();

    this.setProps(props);
  };

  async init(): Promise<Props> {
    const {
      controllerConfig: {
        wixCodeApi: {
          user: { onLogin },
        },
      },
    } = this.params;

    onLogin(this.handleLogin);

    return this.getProps();
  }
}
