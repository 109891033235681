import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';

export const createGoToPricingPlanPickerHandler = (
  handleGoToPricingPlanPicker,
  httpClient,
) => {
  return async (channelId) => {
    const pricingPlansAPI = new PricingPlansAPI(httpClient);

    const plansIds = await pricingPlansAPI.getConnectedPlans(channelId);

    handleGoToPricingPlanPicker(
      plansIds.map((plan) => plan.id),
      channelId,
    );
  };
};
