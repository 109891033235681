import { ControllerParams } from '@wix/yoshi-flow-editor';
import { ReportProps } from '@wix/yoshi-flow-editor/build/cjs/exports/bi';

export abstract class BaseModule<TModuleProps extends object = {}> {
  protected setControllerProps?: (props: Partial<TModuleProps>) => void;

  constructor(protected readonly params: ControllerParams) {}

  protected setProps(props: Partial<TModuleProps>) {
    if (this.setControllerProps) {
      this.setControllerProps(props);
    }
  }

  protected biReport(reportProps: ReportProps) {
    const {
      flowAPI: { bi },
    } = this.params;
    if (!bi) {
      console.warn('BI is not available');
      return;
    }
    bi.report(reportProps);
  }

  abstract init(): Promise<TModuleProps>;
  // todo: we will need to call it somewhere
  async destroy(): Promise<void> {}

  onSetProps(setControllerProps: (props: Partial<TModuleProps>) => void) {
    this.setControllerProps = setControllerProps;
  }
}
