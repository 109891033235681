import { noop } from 'lodash';
import { getModalUrl } from '@wix/yoshi-flow-editor/utils';
import { BaseModule } from './BaseModule';
import { dashify, removeDashes } from '../../utils/guid';
import settingsParams from '../../../../settingsParams';

type Props = {
  openDesktopChannelInfo(): void;
  openDesktopSubscription(): void;
  openAccountInfo(): void;
};

type ResultsTypes = {
  DesktopLightbox: void;
  MobileLightBox: void;
};

type WixCodeOpenPopupResult<T> = { message?: { reason: T } };

type ModalName = keyof ResultsTypes;

export class ModalsModule extends BaseModule<Props> {
  private async openPopup<M extends ModalName>(
    modalName: M,
    hash: string,
    onClose: (result: ResultsTypes[M]) => void = noop,
  ) {
    const {
      controllerConfig: {
        wixCodeApi,
        compId,
        appParams: { instance },
      },
    } = this.params;

    const lightboxUrl = getModalUrl('WixVideo', modalName, instance);
    const url = new URL(lightboxUrl);

    url.hash = hash;
    url.searchParams.append('rootCompId', compId);
    url.searchParams.append(
      'rootPageId',
      wixCodeApi.site.currentPage?.id || '',
    );

    const result: WixCodeOpenPopupResult<ResultsTypes[M]> =
      await wixCodeApi.window.openPopup(
        url.href,
        {
          width: '100%' as any,
          height: '100%' as any,
          position: {
            origin: 'FIXED',
            placement: 'CENTER',
          },
          theme: 'BARE',
        },
        compId,
      );

    onClose(result?.message?.reason);
  }

  private async openDesktopLightbox(hash: string) {
    await this.openPopup('DesktopLightbox', hash);
  }

  private getChannelId = (): string => {
    return dashify(
      this.params.flowAPI.settings.get(settingsParams.channelId),
    ) as string;
  };

  private getV2ChannelId() {
    return removeDashes(this.getChannelId());
  }

  private openDesktopChannelInfo = () => {
    const v2ChannelId = this.getV2ChannelId();
    this.openDesktopLightbox(`#/channel/${v2ChannelId}/info`).catch();
  };

  private openDesktopSubscription = () => {
    const v2ChannelId = this.getV2ChannelId();
    this.openDesktopLightbox(`#/payment/subscription/${v2ChannelId}`).catch();
  };

  private openAccountInfo = () => {
    const v2ChannelId = this.getV2ChannelId();
    this.openDesktopLightbox(`#/account-info/${v2ChannelId}`).catch();
  };

  async init(): Promise<Props> {
    return {
      openDesktopChannelInfo: this.openDesktopChannelInfo,
      openDesktopSubscription: this.openDesktopSubscription,
      openAccountInfo: this.openAccountInfo,
    };
  }
}
