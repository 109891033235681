import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import getOldController from './Widget/old/controller';
import getNewController from './Widget/new/controller';
import { shouldUseNewWidget } from './Widget/new/utils/should-use-new-widget';
import settingsParams from './settingsParams';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI: {
      experiments,
      environment: { isMobile },
    },
    controllerConfig: {
      config: {
        style: {
          styleParams: { numbers },
        },
      },
    },
  } = params;

  const channelLayout = numbers.channelLayout;
  const isChannel = Boolean(
    params.flowAPI.settings.get(settingsParams.channelId),
  );

  const isNewWidget = shouldUseNewWidget(
    channelLayout,
    isMobile,
    isChannel,
    experiments,
  );

  const getController = isNewWidget ? getNewController : getOldController;

  return getController(params);
};

export default createController;
