import { ControllerParams } from '@wix/yoshi-flow-editor';
import { getApiBaseUrl, getV3ToV2MappingBaseUrl } from './index';
import { VIDEO_APP_DEF_ID } from '@wix/wix-vod-constants/common';
import { setInstance } from '../../configure-client';
import { createMemoryHistory } from 'history';
import { setHydratedDataFromSource } from '../../redux/hydrated-data/hydrated-data';
import { setInitialAppSettings } from '../../redux/actions/app-settings';
import { ControllerState } from '../controller-handlers';
import { Handlers } from '../controller-handlers/handlers';
import { updateControllerState } from '../../redux/actions/controller-state';
import { HydratedSource } from './get-hydrated-source';
import { getStoreBuilderForOOI } from './get-store-builder-for-ooi';
import { setCurrentChannelId } from '../../redux/actions/current-channel-id';
import { setCurrentVideoId } from '../../redux/actions/current-video-id';

export interface InitialData extends HydratedSource {
  currentPageId?: string;
}

export async function createStore(
  getStoreBuilder: typeof getStoreBuilderForOOI,
  controllerConfig: ControllerParams['controllerConfig'],
  initialData: InitialData,
  setProps: (props: { [key: string]: any }) => void,
  debug: boolean,
  isSSR: boolean,
  isMobile: boolean,
  isModal: boolean,
  handlers: Handlers,
  getControllerState: () => ControllerState,
) {
  const { wixCodeApi } = controllerConfig;
  const baseUrl = getApiBaseUrl(wixCodeApi);
  const v3ToV2MappingBaseUrl = getV3ToV2MappingBaseUrl(wixCodeApi, {
    isModal,
  });
  const url = '/';
  const { instance } = initialData;
  const configData = { instance, url, baseUrl, v3ToV2MappingBaseUrl };

  const onInstanceChanged = (event: { instance: string }) => {
    const newConfigData = { ...configData, instance: event.instance };
    setInstance(event.instance);
    setProps({ configData: newConfigData });
  };

  wixCodeApi.site.onInstanceChanged(onInstanceChanged, VIDEO_APP_DEF_ID);

  const history = createMemoryHistory({ initialEntries: [url] });

  const { createStore: origCreateStore, setInitialState } =
    getStoreBuilder(isMobile);

  const store = origCreateStore({
    history,
    handlers,
  });
  setInstance(configData.instance);

  store.dispatch(setCurrentChannelId(initialData.channelId));
  store.dispatch(setCurrentVideoId(initialData.videoId));
  store.dispatch<any>(setHydratedDataFromSource(initialData));
  store.dispatch<any>(setInitialAppSettings(initialData.appSettings));

  if (setInitialState) {
    await store.dispatch<any>(setInitialState());
  }

  const controllerState = getControllerState();

  if (controllerState) {
    store.dispatch(updateControllerState(getControllerState()));
  }

  return {
    configData,
    appState: store.getState(),
  };
}
