import { IWixWindow } from '@wix/yoshi-flow-editor';
import {
  PublicChannelItem,
  YoutubeChannelVideosResponse,
  VideoListResponse,
  YoutubeVideo,
} from '@wix/wix-vod-api-site-based/public';
import type { IHttpClient } from '@wix/http-client';
import { getPublicApi } from '../../api/public';
import { MEDIA_TYPES, URL_TYPES } from '@wix/wix-vod-constants/common';
// todo: this is a type from private, we shouldn't use anything from private in widget
import type { VideoResponse } from '@wix/wix-vod-api-site-based/dist/src/private/services/video/video.types';
import { isWixCodeSEOEnabled } from '@wix/wix-vod-shared/common';

type ViewMode = IWixWindow['viewMode']; // can not import the proper type;

interface BaseWidgetData {
  __TRANSLATIONS__: Record<string, string>;
}

interface BaseWidgetDataV2 extends BaseWidgetData {
  __CHANNEL__: PublicChannelItem;
}

interface SingleVideoWidgetDataV2<T extends VideoResponse = VideoResponse>
  extends BaseWidgetDataV2 {
  isSingleVideo: true;
  __SINGLE_VIDEO__: VideoResponse | YoutubeVideo | undefined;
}

interface ChannelWidgetDataV2<T extends VideoResponse = VideoResponse>
  extends BaseWidgetDataV2 {
  isSingleVideo: false;
  __VIDEOS__: VideoListResponse | YoutubeChannelVideosResponse;
}

interface V3V2MappingVideoResponse extends VideoResponse {
  content_url: string;
}

export type WidgetDataV3V2Mapping =
  | SingleVideoWidgetDataV2<V3V2MappingVideoResponse>
  | ChannelWidgetDataV2<V3V2MappingVideoResponse>;

interface GetWidgetDataQueryParams {
  channelId: string;
  videoId?: string;
  instance: string;
  locale?: string;
  videosCount: number;
  metaSiteId?: string;
}

interface GetWidgetDataOptions {
  baseUrl: string;
  viewMode: ViewMode;
  httpClient: IHttpClient;
  translations: Record<string, string>;
  queryParams: GetWidgetDataQueryParams;
}

const getWidgetDataV3V2Mapping = async ({
  queryParams,
  translations,
}: GetWidgetDataOptions): Promise<WidgetDataV3V2Mapping> => {
  const { channelId, videoId, videosCount } = queryParams;
  const publicApi = getPublicApi();
  const channel = (await publicApi.oldPublicChannelService.getInfo(channelId))
    .data;

  // const withPlayInfo = isWixCodeSEOEnabled();
  // for now it was decided not to send the contentUrl value
  // since it's a url with a signed token which expires and won't be available for the bot
  // https://wix.slack.com/archives/C01HMHGN26S/p1691660291999629?thread_ts=1675068385.226839&cid=C01HMHGN26S
  // and requesting with play info is not used by application but makes the request significantly longer
  const withPlayInfo = false;

  const baseWidgetData: BaseWidgetDataV2 = {
    __TRANSLATIONS__: translations,
    __CHANNEL__: channel,
  };

  if (videoId) {
    const requestOptions = withPlayInfo
      ? {
          params: {
            with_play_info: true,
          },
        }
      : undefined;
    const singleVideo = (
      await publicApi.oldPublicChannelVideos.getChannelVideoById(
        channelId,
        videoId,
        requestOptions,
      )
    ).data;
    return {
      ...baseWidgetData,
      __SINGLE_VIDEO__: singleVideo,
      isSingleVideo: true,
    };
  }

  const videos = channel.external_id
    ? (
        await publicApi.oldYoutubeService.getChannelVideos({
          externalId: channel.external_id,
          urlType: channel.url_type as URL_TYPES.CHANNEL | URL_TYPES.PLAYLIST,
          itemsCount: videosCount,
        })
      ).data
    : (
        await publicApi.oldPublicChannelVideos.getList({
          channelId,
          paging: {
            size: videosCount,
          },
          mediaType: MEDIA_TYPES.SECURE_VIDEO,
          withPlayInfo,
        })
      ).data;
  return {
    ...baseWidgetData,
    __VIDEOS__: videos ?? { items: [] },
    isSingleVideo: false,
  };
};

export type WidgetData = WidgetDataV3V2Mapping;

export const getWidgetData = async (
  options: GetWidgetDataOptions,
): Promise<WidgetData> => getWidgetDataV3V2Mapping(options);
