import { BaseModule } from './BaseModule';

type Props = {
  finishedPurchasesCount: number;
};

export class PurchasesModule extends BaseModule<Props> {
  private finishedPurchasesCount = 0;

  private handlePayment = () => {
    this.finishedPurchasesCount++;
    this.setProps({
      finishedPurchasesCount: this.finishedPurchasesCount,
    });
  };

  async init(): Promise<Props> {
    this.params.controllerConfig.platformAPIs.pubSub.subscribe(
      'vod.payment.payment-event',
      this.handlePayment,
      false,
    );

    return {
      finishedPurchasesCount: this.finishedPurchasesCount,
    };
  }
}
