import {
  getNumberOfRows,
  getVideosInRowCount,
  isGridLayout,
  isStripLayout,
  isClassicLayout,
} from '../selectors/app-settings';
import { IWixStyleParams } from '@wix/native-components-infra/dist/src/types/wix-sdk';

// It was the same in the server. Not sure where exactly this number came from.
const DEFAULT_VIDEOS_COUNT = 8;

export const getVideosCount = ({
  isMobile,
  styleParams,
}: {
  isMobile: boolean;
  styleParams: IWixStyleParams;
}): number => {
  if (isMobile) {
    // TODO: why are we returning undefined when it's isMobile???
    return DEFAULT_VIDEOS_COUNT;
  }

  const state = { appSettings: styleParams };

  if (isGridLayout(state) || isStripLayout(state) || isClassicLayout(state)) {
    return getNumberOfRows(state) * getVideosInRowCount(state);
  }
  return DEFAULT_VIDEOS_COUNT;
};
