import { IUser } from '@wix/yoshi-flow-editor';
import { widgetAction } from '@wix/bi-logger-video/v2';
import { BaseModule } from './BaseModule';

type OpenSignIn = (params: {
  mode: 'login' | 'signup';
  language: string;
}) => Promise<void>;

type SignOut = () => void;

type UserHandlerFunctions = {
  openSignIn: OpenSignIn;
  signOut: SignOut;
};

type UserNotLoggedIn = {
  loggedIn: false;
};

export type UserLoggedIn = {
  loggedIn: true;
  id: string;
  email: string;
};

type User = UserLoggedIn | UserNotLoggedIn;

const getUserState = async (user: IUser): Promise<User> =>
  user.loggedIn
    ? {
        loggedIn: true,
        id: user.id,
        email: await user.getEmail(),
      }
    : {
        loggedIn: false,
      };

type UserProps = UserHandlerFunctions & User;

export class UserModule extends BaseModule<UserProps> {
  private pubSubLogoutSubscriptionId: number | undefined;

  private openSignIn: OpenSignIn = async () => {
    const { controllerConfig, flowAPI } = this.params;
    const { wixCodeApi } = controllerConfig;
    const { environment } = flowAPI;
    const { language, isPreview, isEditor } = environment;
    if (isPreview || isEditor) {
      console.log('not for preview');
      return;
    }
    this.biReport(
      widgetAction({
        eventName: 'user.logIn.requested',
      }),
    );
    try {
      const user = await wixCodeApi.user.promptLogin({
        mode: 'login',
        language,
      });
      this.setProps(await getUserState(user));
      this.biReport(
        widgetAction({
          eventName: 'user.logIn.succeeded',
        }),
      );
    } catch (error: any) {
      this.biReport(
        widgetAction({
          eventName: 'user.logIn.cancelled',
        }),
      );
      console.error('error signing in', error);
    }
  };

  private signOut = () => {
    const { wixCodeApi } = this.params.controllerConfig;
    this.biReport(
      widgetAction({
        eventName: 'user.logOut.requested',
      }),
    );
    wixCodeApi.user.logout();
  };

  async init(): Promise<UserProps> {
    const {
      controllerConfig: { wixCodeApi, platformAPIs },
    } = this.params;
    this.pubSubLogoutSubscriptionId = platformAPIs.pubSub.subscribe(
      'vod.session.logout-user',
      this.signOut,
      true,
    );
    return {
      openSignIn: this.openSignIn,
      signOut: this.signOut,
      ...(await getUserState(wixCodeApi.user.currentUser)),
    };
  }

  async destroy() {
    const {
      controllerConfig: { platformAPIs },
    } = this.params;
    if (this.pubSubLogoutSubscriptionId !== undefined) {
      platformAPIs.pubSub.unsubscribe(
        'vod.session.logout-user',
        this.pubSubLogoutSubscriptionId,
      );
    }
  }
}
