import { ControllerParams } from '@wix/yoshi-flow-editor';

export type FetchModuleProps = {
  doPost: (requestId: string, uri: string, body: string) => void;
  removeResponse: (requestId: string) => void;
  responses: Record<string, any>;
};

export class FetchModule {
  private responsesMap = new Map<string, any>();

  constructor(private readonly controllerParams: ControllerParams) {}

  setProps = () => {
    const {
      controllerConfig: { setProps },
    } = this.controllerParams;

    const responses: FetchModuleProps['responses'] = Object.fromEntries(
      this.responsesMap,
    );

    setProps({ responses });
  };

  doPost = async (requestId: string, uri: string, body: string) => {
    const {
      flowAPI: { httpClient },
    } = this.controllerParams;

    const data = JSON.parse(body);
    const response = await httpClient.post(uri, data);
    this.responsesMap.set(requestId, response.data);

    this.setProps();
  };

  removeResponse = async (requestId: string) => {
    this.responsesMap.delete(requestId);
    this.setProps();
  };

  getInitialProps(): FetchModuleProps {
    return {
      doPost: this.doPost,
      removeResponse: this.removeResponse,
      responses: {},
    };
  }
}
