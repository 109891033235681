import { ControllerParams } from '@wix/yoshi-flow-editor';
import { ModalsModule } from './ModalsModule';
import { PurchasesModule } from './PurchasesModule';
import { PricingPlansModule } from './PricingPlansModule';
import { UserModule } from './UserModule';
import { BaseModule } from './BaseModule';

type BaseModuleToExtend = BaseModule;
type PropsOfModule<T extends BaseModuleToExtend> = T extends BaseModule<infer M>
  ? M
  : never;

type PropsOfModules<T extends { [k: string]: BaseModuleToExtend } = {}> = {
  [K in keyof T]: PropsOfModule<T[K]>;
};

type ModuleRegistry<T extends { [k: string]: BaseModuleToExtend } = {}> = {
  register<K extends string, V extends BaseModuleToExtend>(
    name: K,
    value: V,
  ): ModuleRegistry<T & { [P in K]: V }>;
  getProps(): Promise<PropsOfModules<T>>;
};

const createModuleRegistryFactory = (params: ControllerParams) => {
  const createModuleRegistry = <
    T extends { [k: string]: BaseModuleToExtend } = {},
  >(
    modules: T,
  ): ModuleRegistry<T> => {
    const setProps = (newProps: Partial<PropsOfModules<T>>) =>
      params.controllerConfig.setProps(newProps);

    return {
      register<K extends string, V extends BaseModuleToExtend>(
        moduleName: K,
        module: V,
      ) {
        const newModules = {
          ...modules,
          [moduleName]: module,
        };

        return createModuleRegistry(newModules) as ModuleRegistry<
          T & { [P in K]: V }
        >;
      },
      async getProps() {
        const modulesEntries = Object.entries(modules);
        const propValues = await Promise.all(
          modulesEntries.map(([, module]) => module.init()),
        );
        const props = Object.fromEntries(
          propValues.map((propValue, i) => [modulesEntries[i][0], propValue]),
        ) as PropsOfModules<T>;
        const setNewPropsTo =
          <
            TKey extends keyof PropsOfModules<T>,
            TValue extends Partial<PropsOfModules<T>[TKey]>,
          >(
            moduleKey: TKey,
          ) =>
          (newModuleProps: TValue) => {
            const currentModuleProps = props[moduleKey];

            props[moduleKey] = {
              ...currentModuleProps,
              ...newModuleProps,
            };

            setProps({
              [moduleKey]: props[moduleKey],
            } as unknown as Partial<PropsOfModules<T>>);
          };
        modulesEntries.forEach(([prop, module]) => {
          module.onSetProps(setNewPropsTo(prop));
        });

        return props;
      },
    };
  };
  return createModuleRegistry;
};

export async function createModules(params: ControllerParams) {
  const modalsModule = new ModalsModule(params);
  const purchasesModule = new PurchasesModule(params);
  const pricingPlansModule = new PricingPlansModule(params);
  const userModule = new UserModule(params);

  const createModuleRegistry = createModuleRegistryFactory(params);
  const moduleRegistry: ModuleRegistry = createModuleRegistry({});
  return moduleRegistry
    .register('modals', modalsModule)
    .register('purchases', purchasesModule)
    .register('pricingPlans', pricingPlansModule)
    .register('user', userModule)
    .getProps();
}
